import React from "react";
import loadable from "@loadable/component";
import Layout from "../../../components/Layout/layout";
import SEO from "../../../components/seo";
import heroBg from "../../../images/hero-images/fine-dining-hero.png";
import {
  msdHero,
  msdIconTemplate,
  msdMultiSection,
  msdList,
  msdTestimonials,
  msdWhiteGlove,
  splitSection,
  customArr,
} from "../../../data/Misc/msd";
import HeadingBuilder from "../../../components-v2/Base/HeadingBuilder";
import Image from "../../../components/ImageQuerys/RandomImages";
import AppetizeImage from "../../../components/ImageQuerys/AppetizeImages";
import check from "../../../images/svg/checkmark.svg";
import CtaPrimary from "../../../components-v2/Base/CtaPrimary";

const Hero = loadable(() => import("../../../components/Hero/hero"));
const Restaurants = loadable(() =>
  import("../../../components/ColoradoPortedComps/ModdedRest")
);
const WhiteGlove = loadable(() =>
  import("../../../components/WhiteGlove/WhiteGlove")
);
const SecondaryHeroTitleTwoCols = loadable(() =>
  import("../../../components-v2/Sections/Hero/SecondaryHeroTitleTwoCols")
);
const LargeFeatures = loadable(() =>
  import("../../../components/LargeFeatures/LargeFeatures")
);
const TestmonialReviews = loadable(() =>
  import("../../../components/TestimonialReviews/TestmonialReviews")
);
const PartnerLogos = loadable(() =>
  import("../../../components/PartnerLogos/PartnerLogos")
);

const imgLib = [
  "foodwise.png",
  "washington-hospitality.png",
  "the-mra.png",
  "nyc.png",
  "olm.png",
];

const AlliedAssociations = () => {
  return (
    <Layout
      isLanding
      phoneText="(888) 224-6506"
      className="relative"
      phoneNumber="8882246506"
    >
      <SEO
        title="SpotOn | Allied Associations | Member Savings Discounts"
        description="Learn how association members save big with SpotOn Restaurant POS, SpotOn Reserve, or SpotOn's labor management solutions."
        image={`https://spoton.com/${heroBg}`}
        cannonical="https://www.spoton.com/msd/allied-associations"
        productSchemaSR
      />
      <Image
        imageName="msd-circle.png"
        className="absolute w-[130px] lg:right-[3%] xl:right-[7%] top-[150px] hidden lg:block"
      />
      <Hero sectionData={msdHero} className="xl:pt-16" />
      <section className="max-w-[1000px] mx-auto mt-[132px] md:mt-[100px] mb-[100px] md:mb-[150px]">
        <HeadingBuilder
          level={2}
          injectionType={3}
          className="text-center max-w-[730px] mx-auto"
          withBlueSymbol
          symbol="."
        >
          Proud partner of these associations and many others
        </HeadingBuilder>
        <div className="grid grid-cols-2 lg:grid-cols-5 gap-6 mx-auto p-4">
          {imgLib.map((img, idx) => (
            // <Image
            //   imageName={img}
            //   className={`${
            //     idx === 1
            //       ? "w-[250px] translate-x-[-40px] md:translate-x-0 lg:translate-x-[-50px]"
            //       : "w-[150px]"
            //   } mx-auto`}
            //   objectFit="scale-down"
            // />

            <Image
              key={img}
              imageName={img}
              className="w-[150px] mx-auto"
              objectFit="scale-down"
            />
          ))}
        </div>
      </section>
      <Restaurants data={msdIconTemplate} nav="pos" noHeading noCta />
      <section className="mt-[80px] mb-[120px] px-4 xl:p-0 relative">
        <HeadingBuilder
          level={2}
          injectionType={3}
          className="text-center mb-8"
          withBlueSymbol
          symbol="."
        >
          SpotOn Restaurant
        </HeadingBuilder>
        <p className="text-black-300 text-[20px] text-center max-w-[964px] mx-auto">
          Are you looking to simplify your operation while boosting sales? It’s
          now easier than ever before with SpotOn. Our platform is easy to learn
          and cloud-based, ensuring the best performance—always.
        </p>
        <AppetizeImage
          className="rounded-large image-desktop max-w-[1120px] mx-auto my-[64px]"
          imageName="msd-split.png"
        />
        <div className="hidden md:grid grid-cols-2 max-w-[1120px] mx-auto h-auto md:gap-x-[20%] xl:gap-x-0">
          {msdList.map((item, idx) =>
            idx % 2 === 0 ? (
              <div className="flex-1 mb-[64px] relative">
                <img
                  src={check}
                  className="inline-block mr-6 absolute"
                  alt=""
                  style={{ top: "15%", left: "0px" }}
                />
                <div className="inline-block ml-12">
                  <HeadingBuilder
                    level={3}
                    injectionType={4}
                    withBlueSymbol
                    noMargin
                    symbol="."
                  >
                    {item.title}
                  </HeadingBuilder>
                  <p className="max-w-[400px] mt-0">{item.subtext}</p>
                </div>
              </div>
            ) : (
              <div className="mb-[64px] justify-self-end relative">
                <img
                  src={check}
                  className="inline-block mr-6 absolute"
                  style={{ top: "15%", left: "-50px" }}
                  alt=""
                />
                <div className="inline-block">
                  <HeadingBuilder
                    level={3}
                    injectionType={4}
                    withBlueSymbol
                    symbol="."
                    noMargin
                  >
                    {item.title}
                  </HeadingBuilder>
                  <p className="max-w-[400px] mt-0">{item.subtext}</p>
                </div>
              </div>
            )
          )}
        </div>
        <div className="table mx-auto">
          <CtaPrimary title="Get a Demo" target="/associations/demo/" />
        </div>
      </section>
      <section className="w-full relative">
        <TestmonialReviews sectionData={msdTestimonials} />
      </section>
      <PartnerLogos customArr={customArr} />
      <LargeFeatures
        sectionData={msdMultiSection}
        style={{ marginTop: "128px" }}
      />
      <section className="mt-[144px]">
        <WhiteGlove
          sectionData={msdWhiteGlove}
          whiteGloveBg="msd-white-glove.png"
        />
      </section>
      <section className="mb-[120px] mt-[100px]">
        <SecondaryHeroTitleTwoCols {...splitSection} />
      </section>
    </Layout>
  );
};

export default AlliedAssociations;
