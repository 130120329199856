// eslint-disable-next-line import/prefer-default-export
export const msdHero = {
  title: "Bigger savings with better restaurant technology solutions",
  subtext:
    "As an association member, you can choose how you want to save with SpotOn. Either get a credit starting at $500 towards your first POS station or receive a free consultation, set up, and trial of SpotOn Reserve or SpotОn labor management solution.",
  PrimaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/associations/demo/",
  },
  heroImg: "msd-hero-mobile.png",
  desktopImageClassName: "md:w-[75%]",
  heroMobileImg: "msd-hero-mobile.png",
  mobileImageClassName:
    "max-w-[450px] translate-y-[10%] md:max-w-auto md:w-[600px]",
  video: {
    videoBtn: "Watch video",
    posterWrapperClass: "",
    posterClass:
      "translate-y-[-5%] md:translate-y-[5%] lg:translate-y-[10%] md:translate-x-[20px]",
    posterImg: "msd-poster.png",
    videoUrlId: "kFpC6wLKu2s",
  },
};

export const msdIconTemplate = [
  {
    content: "Casual & fine dining",
    desc: "Create a better guest experience and higher profit margins with a restaurant POS for full-service establishments.",
    img: "casual-dining.png",
  },
  {
    content: "Fast casual",
    desc: "Pair your high-quality food with a high-tech experience and give guests a faster way to get their next meal.",
    img: "fc.png",
  },
  {
    content: "Bars & nightclubs",
    desc: "Mix and serve more drinks with lightning-fast performance and an intuitive interface that won't get in your way.",
    img: "Bars-and-nightclubs.png",
  },
  {
    content: "Quick service",
    desc: "Keep checkout lines moving and stand out from competitors with your own mobile app and online menu for customers.",
    img: "QSR.png",
  },
  {
    content: "Enterprise",
    desc: "Monitor and manage multiple location with real-time reporting and instant updates across all of your sites.",
    img: "enterprise-icon.png",
  },
  {
    content: "Pizzeria",
    desc: "Easily create and manage your customer database and offer multiple ways for customers to order and pay.",
    img: "za.png",
  },
];

export const msdList = [
  {
    title: "Intuitive front-of-house",
    subtext:
      "Streamline operations, accelerate growth, and provide guests with a first-class experience, in-house or online.",
  },
  {
    title: "Third-party integrations",
    subtext:
      "Unlock more potential with powerful built-in tools and compatibility with many third-party services.",
  },
  {
    title: "Powerful back-of-house",
    subtext:
      "Boost efficiency with kitchen display screens and multi-language tickets while saving money with payroll integration.",
  },
  {
    title: "Optimized labor management",
    subtext:
      "Save on labor costs with powerful scheduling, reporting, compliance, and payroll tools that keep your crew running like clockwork.",
  },
  {
    title: "Handheld POS and QR ordering",
    subtext:
      "Turn tables faster, even with fewer servers, with handheld POS devices and QR ordering that go directly to your POS in real-time.",
  },
  {
    title: "Online ordering",
    subtext:
      "Save compared to third-party delivery apps and own your guest data with commission-free online ordering.",
  },
  {
    title: "Elite guest data & reporting",
    subtext:
      "Stay informed with the free mobile reporting app to access guest data anytime or anywhere.",
  },
  {
    title: "Reservations",
    subtext:
      "Easily manage your waitlist and reservation system in real-time with the SpotOn Reserve front-of-house solution.",
  },
];

export const msdWhiteGlove = {
  mainTitle: "Your local support team",
  title: "Providing businesses with in-person & local support since 2013",
  content:
    "You deserve to do business with someone you can look in the eye, with a proven track record of helping other local businesses. SpotOn has a dedicated, local team of professionals at your service, backed by 24/7 technical support only a phone call away.",
  ctaEmail: {
    ctaMail: "info@spoton.com",
    ctaText: "Send an email",
  },
  ctaPhone: {
    phoneNumber: "(888) 224-5606",
    phoneText: "(888) 224-5606",
  },
};

export const msdMultiSection = {
  featureBlocks: [
    {
      blockTitle: "Online ordering",
      blockSubTitle:
        "With digital solutions for takeout, delivery, and on-premise dining, SpotOn helps increase throughput to boost revenue with significant cost savings compared to third-party services.",
      blockList: [
        "Online ordering from your website",
        "SpotOn Delivery powered by DoorDash",
        "SpotOn QR codes",
        "E-gift cards",
        "Direct POS integration",
        "Own your guest data",
      ],
      blockImg: "msd-a.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Get a demo",
        ctaTarget: "/associations/demo/",
      },
    },
    {
      blockTitle: "Reservations & waitlisting",
      blockSubTitle:
        "Book more reservations, serve more guests, and easily manage waitlists while delivering a VIP guest experience with SpotOn Reserve.",
      blockImg: "msd-b.png",
      blockList: [
        "24-hour online booking",
        "Waitlist & real-time notifications",
        "Server & table management",
        "Reserve with Google listing",
        "Standalone solution or POS integration",
      ],
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Get a demo",
        ctaTarget: "/associations/demo/",
      },
    },
    {
      blockTitle: "Contactless payments",
      blockSubTitle:
        "Create an end-to-end contactless experience to keep your customers safe and the checkout process moving faster than ever before.",
      blockList: [
        "Handheld POS",
        "QR code on the receipt",
        "Credit card terminal",
        "Consumer-facing displays",
        "Mobile dine-in ordering",
        "Mobile card readers",
      ],
      blockImg: "msd-c.png",
      ImgAltTag: "nightclub and bar pos",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Get a demo",
        ctaTarget: "/associations/demo/",
      },
    },
    {
      blockTitle: "Marketing & loyalty",
      blockSubTitle:
        "Improve customer loyalty, manage your online reputation, and drive repeat visits with fully-integrated customer engagement tools to help your business grow.",
      blockImg: "msd-d.png",
      ImgAltTag: "nightclub and bar pos",
      blockList: [
        "Marketing campaigns",
        "Review management",
        "Digital loyalty",
        "Online dashboard",
        "Mobile dashboard app",
        "Guest and campaign analytics",
      ],
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Get a demo",
        ctaTarget: "/associations/demo/",
      },
    },
  ],
};

export const customArr = [
  "thunderdome-logo.png",
  "neai-logo.png",
  "celebrity-nails-logo.png",
  "LoDo-logo.png",
  "waukesha-logo.png",
  "coohills_logo.png",
  "public-house-logo.png",
  "mile-high-logo.png",
];

export const splitSection = {
  title: "Service and support",
  subtext:
    "Get the local in-person support your business deserves and around-the-clock technical support—included at no extra cost.",
  image: {
    imageName: "msd-secondary-hero.png",
    mobileImageName: "msd-secondary-hero-mobile.png",
  },
  blockList: [
    "In-person hardware installation",
    "Face-to-face staff training",
    "Ongoing virtual training",
  ],
  TitleSubCta: {
    title: "Get a demo",
    target: "/associations/demo/",
  },
};

export const msdTestimonials = {
  title: "What other businesses have to say",
  reviews: [
    {
      quote:
        "From the initial consultation, to ordering, to implementation and integration, the entire process of on-boarding the SpotOn POS System has been seamless and has exceeded our expectations.",
      reviewDate: "Mar 9, 2021",
      reviewer: "Pete M.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn continues to be an invaluable technology partner that listens well and proactively presents innovative solutions to drive our business forward.",
      reviewDate: "Feb 9, 2021",
      reviewer: "Ben P.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn has given me the data access I need to manage my business from a macro as well as micro perspective. The software is easy to access and utilize.",
      reviewDate: "Dec 5, 2020",
      reviewer: "Pamela H.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "Every issue and question has been answered or addressed within hours, if not minutes.I can't say enough good about this company and software.",
      reviewDate: "Feb 24, 2021",
      reviewer: "Brandy R.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "From back office, digital marketing, built our website to take orders off of. The programming is great to allow you to do more tasks with better trained staff.",
      reviewDate: "Dec 1, 2020",
      reviewer: "Marcus O.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
  ],
};
